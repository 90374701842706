<template>
  <div>
    <b-container>
      <Heading type="h1" :title="trans('global.dashboard')" />
      <b-row class="dashboard-stats align-content-strech flex-wrap">
        <b-col cols="6">
          <vary-card title="Prospektdesign" description="De siste aktive prospekt-design-valgene, live oppdatert!">
            <div
              v-if="Array.isArray(prospectChoices) && prospectChoices.length === 0"
              class="d-flex justify-content-center align-items-center flex-column mt-3"
            >
              <b-spinner variant="primary"></b-spinner>
              <p class="text-muted mt-3">
                {{ loadingMessage }}
              </p>
            </div>
            <div v-else-if="prospectChoices === null">
              <p>Ingen funnet</p>
            </div>
            <div v-else>
              <div class="d-flex">
                <b-form-select
                  v-model="selectedProject"
                  :options="optionsProjects"
                  size="sm"
                  class="mb-3 mr-2"
                  @change="filterProspectChoices"
                />
                <b-form-select
                  v-model="selectedUsergroup"
                  :options="optionsUsergroups"
                  size="sm"
                  class="mb-3"
                  @change="filterProspectChoices"
                />
              </div>
              <!--begin::list-group-->
              <transition-group name="prospect-list">
                <!--begin::list-wrapper-->
                <div
                  v-for="(choice, index) in prospectChoices"
                  :key="choice._id + index"
                  class="dashboardlist prospect-list-item rounded mb-2 p-2"
                >
                  <b-link
                    class="d-flex"
                    @click="toggleCollapse(choice._id + index)"
                  >
                    <!--begin::list-image-->
                    <div class="mr-2">
                      <b-img
                        rounded
                        :src="prospectChoiceImage(choice.project)"
                      ></b-img>
                    </div>
                    <!--end::list-image-->

                    <!--begin::info-->
                    <div class="flex-grow-1 position-relative">
                      <p class="mb-0">
                        {{ choice.prospect.title }}
                      </p>
                      <p class="text-muted font-size-small mb-0">
                        {{ choice.project.name }}
                      </p>
                      <p class="font-size-small mb-0" style="text-transform: capitalize;">
                        {{ choice.modified.seconds | moment("dddd, MMMM Do YYYY") }} kl: {{ choice.modified.seconds | moment("HH:mm:ss") }}
                      </p>
                      <b-badge
                        :variant="choice.user.profile !== undefined ? 'success' : 'secondary'"
                        class="badge-absolute px-2 py-1 font-weight-normal"
                      >
                        <div v-if="choice.user.profile !== undefined">
                          {{ choice.user.profile.firstName || 'ukjent' }} {{ choice.user.profile.lastName || 'ukjent' }}
                        </div>
                        <div v-else>
                          Gjestebruker
                        </div>
                      </b-badge>
                    </div>
                    <!--end::info-->
                  </b-link>
                  <b-collapse
                    :visible="toggled.includes(choice._id + index)"
                    class="font-size-small"
                  >
                    <p class="mt-4 mb-1">
                      Detaljer:
                    </p>
                    <div>
                      <span>
                        IP:
                      </span>
                      <span>
                        {{ choice.userIp }}
                      </span>
                    </div>
                    <div>
                      <span>
                        Id:
                      </span>
                      <span>
                        {{ choice.userId }}
                      </span>
                    </div>
                    <div>
                      <span>
                        Design navn:
                      </span>
                      <span>
                        {{ choice.name }}
                      </span>
                    </div>
                  </b-collapse>
                </div>
                <!--end::list-wrapper-->
              </transition-group>
              <!--end::list-group-->
            </div>
          </vary-card>
        </b-col>
        <b-col cols="6">
          <vary-card title="Prospektdesign statistikk" description="Designs de siste 30 dagene">
            <div class="w-100 text-right">
              <b-button
                variant="light"
                style="position: absolute; top: 10px; right: 10px;"
                @click="generateReport"
              >
                Oppdater
              </b-button>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column mt-3">
              <div v-if="!statsDaily" class="d-flex flex-column align-items-center">
                <b-spinner variant="primary"></b-spinner>
                <p class="text-muted mt-3">
                  Henter statistikk fra de siste 30 dagene
                </p>
              </div>
              <canvas id="stats-daily"></canvas>
            </div>
          </vary-card>
        </b-col>
      </b-row>
      <b-row class="dashboard-stats align-content-strech flex-wrap margin-top-26">
        <b-col cols="6">
          <vary-card title="Bestillinger" description="De siste 5 bestillingene, live oppdatert!">
            <div v-if="orders.length === 0" class="d-flex justify-content-center align-items-center flex-column mt-3">
              <b-spinner variant="primary"></b-spinner>
              <p class="text-muted mt-3">
                Henter de siste ordrene...
              </p>
            </div>
            <div v-else>
              <div
                v-for="(order, index) in orders"
                :key="order._id + index"
                class="dashboardlist prospect-list-item rounded mb-2 p-2"
              >
                <b-link
                  class="d-flex flex-column"
                  @click="toggleCollapse(order._id + index)"
                >
                  <p class="font-size-tiny mb-0 text-muted" style="text-transform: capitalize">
                    {{ order.createdOn.toDate() | moment("dddd, MMMM Do YYYY") }}
                  </p>
                  <p class="mb-0">
                    {{ order.data.project.title }} - {{ order.data.prospect.title }}
                  </p>
                  <p class="font-size-small mb-0">
                    {{ order.user.firstName }} {{ order.user.lastName }}
                  </p>
                </b-link>
                <b-collapse
                  :visible="toggled.includes(order._id + index)"
                  class="font-size-small"
                >
                  <p class="mt-4 mb-1 font-weight-bolder">
                    Prosjekt:
                  </p>
                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      ID:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.project.id }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Navn:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.project.title }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Beskrivelse:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.project.description }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Varykode:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.project.varycode }}
                    </span>
                  </div>

                  <p class="mt-4 mb-1 font-weight-bolder">
                    Prospekt:
                  </p>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      ID:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.prospect.id }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Navn:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.prospect.title }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Varykode:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.prospect.varycode }}
                    </span>
                  </div>

                  <p class="mt-4 mb-1 font-weight-bolder">
                    Bruker:
                  </p>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Navn:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.user.firstName }} {{ order.user.lastName }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Addresse:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.user.profile.address }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Email:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.data.user.email }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Telefon:
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ order.user.phone }}
                    </span>
                  </div>
                </b-collapse>
              </div>
            </div>
          </vary-card>
        </b-col>
        <b-col cols="6">
          <vary-card title="Leads" description="De siste 5 forespørslene, live oppdatert!">
            <div v-if="requests.length === 0" class="d-flex justify-content-center align-items-center flex-column mt-3">
              <b-spinner variant="primary"></b-spinner>
              <p class="text-muted mt-3">
                Henter de siste leadsene...
              </p>
            </div>
            <div v-else>
              <div
                v-for="(request, index) in requests"
                :key="request._id + index"
                class="dashboardlist prospect-list-item rounded mb-2 p-2"
              >
                <b-link
                  class="d-flex flex-column"
                  @click="toggleCollapse(request._id + index)"
                >
                  <p class="font-size-tiny mb-0 text-muted" style="text-transform: capitalize">
                    {{ request.createdOn.toDate() | moment("dddd, MMMM Do YYYY") }}
                  </p>
                  <p class="mb-0">
                    {{ request.data.prospect }}
                  </p>
                  <p class="font-size-small mb-0">
                    {{ request.data.form.name }} ( {{ request.data.form.email }} )
                  </p>
                </b-link>
                <b-collapse
                  :visible="toggled.includes(request._id + index)"
                  class="font-size-small"
                >
                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Telefon
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ request.data.form.phone || 'ukjent telefon' }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Epost
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ request.data.form.email || 'ukjent epost' }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Har gjort designvalg?
                    </span>
                    <span class="flex-grow-1 w-50">
                      {{ request.data.form.choices ? 'Ja' : 'Nei' }}
                    </span>
                  </div>

                  <div class="d-flex">
                    <span class="flex-grow-1 w-50">
                      Prospektdesignlink
                    </span>
                    <b-link
                      v-if="request.data.form.url"
                      :href="request.data.form.url"
                      class="flex-grow-1 w-50"
                    >
                      Se designvalgene
                    </b-link>
                    <span v-else class="flex-grow-1 w-50">
                      Ikke delt
                    </span>
                  </div>
                </b-collapse>
              </div>
            </div>
          </vary-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Chart from 'chart.js'
  import Heading from '../components/Core/Heading'
  import VaryCard from '../components/Core/Card'

  const fb = require('../firebaseConfig')

  export default {
    name: 'Dashboard',
    components: {
      Heading,
      'vary-card': VaryCard
    },
    data: () => ({
      toggled: [],
      statsDaily: false,
      selectedProject: null,
      selectedUsergroup: null,
      optionsUsergroups: [
        { value: null, text: 'Alle brukere' },
        { value: 'reg', text: 'Registrerte brukere' }
      ]
    }),
    computed: {
      ...mapGetters({
        loadingMessage: 'dashboard/getLoadingMessage',
        requests: 'dashboard/getRequests',
        orders: 'dashboard/getOrders',
        prospectChoices: 'dashboard/getProspectChoices'
      }),
      optionsProjects () {
        let projects = this.$store.getters['dashboard/getActiveProjects']
        const defaultChoices = [
          { value: null, text: 'Alle prosjekter' }
        ]

        projects = projects || []

        return [...defaultChoices, ...projects]
      }
    },
    mounted () {
      this.$store.dispatch('dashboard/listenToProspectChoices')
      this.$store.dispatch('dashboard/listenToOrders')
      this.$store.dispatch('dashboard/listenToRequests')
      this.generateReport()
    },
    beforeMount () {
      this.updateBreadcrumb([{ text: this.trans('global.dashboard'), to: '#' }])
    },
    methods: {
      prospectChoiceImage (project) {
        let image = 'https://via.placeholder.com/64'
        if (project.image !== undefined && Object.keys(project.image).length > 0) {
          const imageData = project.image[Object.keys(project.image)[Object.keys(project.image).length - 1]]
          image = process.env.VUE_APP_IMAGE_CDN_URL + '/' + imageData.path + '/' + imageData.file + '?fit=crop&w=64&h=64'
        }

        return image
      },
      toggleCollapse (id) {
        if (this.toggled.includes(id)) {
          this.toggled = this.toggled.filter(key => key !== id)
        } else {
          this.toggled.push(id)
        }
      },
      async generateReport () {
        // Fetching ALL prospectDesigns
        const allProspectChoicesForReport = await fb.prospectChoicesCollection.orderBy('modified', 'desc').get()
          .then((res) => {
            const allChoices = []

            res.forEach((doc) => {
              const docData = doc.data()
              allChoices.push({
                id: doc.id,
                date: docData.modified.seconds
              })
            })

            return allChoices
          })

        this.getDailyStats(allProspectChoicesForReport, 30)
      },
      getDailyStats (choicesArray, maxDays) {
        const result = []
        let currentDay = 0

        const limit = this.$moment().subtract(maxDays, 'days') // Last date limit

        while (currentDay < maxDays) {
          const currentDate = this.$moment().subtract(currentDay, 'days')
          let dailyCount = 0

          // Get dates as long as the date is not older than the specified amount of days
          choicesArray.every((day) => {
            const currentChoiceDate = this.$moment.unix(day.date)

            if (currentChoiceDate.isSame(currentDate, 'day')) {
              dailyCount++
            }

            return currentChoiceDate.isAfter(limit) // As long as the current date is bigger than reqested
          })

          result[currentDay] = {
            day: currentDate.format('dddd'),
            count: dailyCount
          }

          dailyCount = 0
          currentDay++
        }

        // Generate the chart data
        this.statsDaily = {
          type: 'bar',
          data: {
            labels: result.map(item => item.day).reverse(),
            datasets: [{
              backgroundColor: 'rgba(80,20,69,0.8)',
              data: result.map(item => item.count).reverse()
            }]
          },
          options: {
            legend: {
              display: false
            }
          }
        }

        this.createStatsChart('stats-daily', this.statsDaily)
      },
      createStatsChart (chartId, chartData) {
        const ctx = document.getElementById(chartId)
        const myChart = () => new Chart(ctx, chartData)
        myChart()
      },
      filterProspectChoices () {
        const params = {}

        if (this.selectedProject) {
          params.project = this.selectedProject
        }

        if (this.selectedUsergroup) {
          params.group = this.selectedUsergroup
        }

        this.$store.commit('dashboard/setParams', params)
        this.$store.dispatch('dashboard/synchronizeProspectChoicesData')
      }
    }
  }
</script>

<style lang="scss">
  .prospect-list-item {
    opacity: 1;
  }

  .prospect-list-enter {
    background-color: green !important;
  }

  .prospect-list-enter-to {
    background-color: #f0f0f0 !important;
  }

  .prospect-list-enter-active {
    transition: all 0s ease;

    &:first-child {
      transition: all 4s ease;
    }
  }

  .prospect-list-leave-active {
    transition: all 0s ease;
  }

  .badge-absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
